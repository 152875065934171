<template>
  <div class="point-sensor-list" :style="bindStyle">
    <div v-for="(ps, i) in pointSensorList" :key="i">
      <v-tooltip
        top
        :open-on-click="false"
        :open-on-hover="false"
        v-model="ps.visibleTooltip"
      >
        <template v-slot:activator="{ attrs }">
          <div
            class="point-sensor"
            v-bind="attrs"
            :style="bindStylePointSensor(ps)"
            @click="click().then((event) => event(ps))"
            @dblclick="dbclick().then((event) => event(ps))"
          />
        </template>
        <div class="tooltip">
          <div>{{ ps.sensor.location }}</div>
          <div class="tooltip-percent">
            <div class="tooltip-color sensor-red" :style="bindStyle" />
            <span>{{ dispPercentRed(ps) }}</span>
          </div>
          <div class="tooltip-percent">
            <div class="tooltip-color sensor-yellow" :style="bindStyle" />
            <span>{{ dispPercentYellow(ps) }}</span>
          </div>
          <div class="tooltip-percent">
            <div class="tooltip-color sensor-green" :style="bindStyle" />
            <span>{{ dispPercentGreen(ps) }}</span>
          </div>
        </div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import constant from "../store/constant";
import mixinApp from "../store/mixin.app";
import mixinPoint from "../store/mixin.point";
import store from "../store";

const vm = {
  mixins: [mixinApp, mixinPoint],
  props: {
    propPoint: null,
    propSensorCount: 0,
  },
  data() {
    return {
      timeout: null,
    };
  },
  computed: {
    bindStyle() {
      return Object.assign(this.bindStylePoint, {
        "--point-sensor-size": this.pointSensorSize() + "px",
        "--point-sensor-margin": this.pointSensorMargin() + "px",
        "--sub-background-color": "black",
        "--point-sensor-width": this.pointSensorSize() * this.maxSensorWidth(this.propSensorCount) + "px",
      });
    },
    area() {
      return this.propArea;
    },
    point() {
      return this.propPoint;
    },
    pointSensorList() {
      return this.point.filterPointSensorList(
        this.area.condition,
        this.hierarchy
      );
    },
  },
  methods: {
    bindStylePointSensor(ps) {
      return Object.assign({}, this.bindStyle, {
        "--point-sensor-color": ps.getColor(),
      });
    },
    pointSensorHeight() {
      const h = this.getListHeight();

      return h - h * 0.3;
    },
    pointSensorWidth() {
      const w = this.getListWidth() / this.area.countMax(this.hierarchy);

      return w - w * 0.3;
    },
    pointSensorSize() {
      return this.pointSensorHeight() < this.pointSensorWidth()
        ? this.pointSensorHeight()
        : this.pointSensorWidth();
    },
    pointSensorMargin() {
      return this.pointSensorSize() * 0.1;
    },
    getPercent(val) {
      return Math.floor(val * 100);
    },
    dispPercent(val) {
      return val + "%";
    },
    dispPercentTotal(ps){
      return this.getPercent(ps.getPercentGreen()) +
            this.getPercent(ps.getPercentYellow()) +
            this.getPercent(ps.getPercentRed());
    },
    getTotalGap(ps){
      if (this.dispPercentTotal(ps) == 0)
      {
        return 0;
      }
      return 100 - this.dispPercentTotal(ps);
    },
    getGreenGap(ps){
      return ps.getPercentGreen() - this.getPercent(ps.getPercentGreen());
    },
    getYellowGap(ps){
      return ps.getPercentYellow() - this.getPercent(ps.getPercentYellow());
    },
    getRedGap(ps){
      return ps.getPercentRed() - this.getPercent(ps.getPercentRed());
    },    
    /** 緑の割合算出。切り捨ての余り値を寄せないため合計しても100%にはならない場合がある。
     * @see model.point.sensor.js => getPercentRed / getPercentYellow / getPercentGreen */
    dispPercentGreen(ps) {
      let percent = this.getPercent(ps.getPercentGreen());
      if(this.getTotalGap(ps) >= 2 && (
       this.getGreenGap(ps) >= this.getYellowGap(ps) ||
       this.getGreenGap(ps) >= this.getRedGap(ps))){
        percent =  percent + 1;
      } else if(this.getTotalGap(ps) >= 1 && 
       this.getGreenGap(ps) >= this.getYellowGap(ps) &&
       this.getGreenGap(ps) >= this.getRedGap(ps)){
        percent =  percent + 1;
      }

      return this.dispPercent(percent);
    },
    /** 黄の割合算出 */
    dispPercentYellow(ps) {
      let percent = this.getPercent(ps.getPercentYellow());
      if(this.getTotalGap(ps) >= 2 && (
        this.getYellowGap(ps) > this.getGreenGap(ps) ||
        this.getYellowGap(ps) > this.getRedGap(ps))){
        percent =  percent + 1;
      } else if(this.getTotalGap(ps) >= 1 && 
        this.getYellowGap(ps) > this.getGreenGap(ps) &&
        this.getYellowGap(ps) > this.getRedGap(ps)){
        percent =  percent + 1;
      }
      return this.dispPercent(percent);
    },
    /** 赤の割合算出 */
    dispPercentRed(ps) {
      let percent = this.getPercent(ps.getPercentRed());
      if(this.getTotalGap(ps) >= 2 && (
        this.getRedGap(ps) > this.getGreenGap(ps) ||
        this.getRedGap(ps) > this.getYellowGap(ps)) ){
        percent =  percent + 1;
      } else if(this.getTotalGap(ps) >= 1 && 
        this.getRedGap(ps) > this.getGreenGap(ps) &&
        this.getRedGap(ps) > this.getYellowGap(ps) ){
        percent =  percent + 1;
      }
      return this.dispPercent(percent);
    },
    // /** 緑の割合算出。切り捨ての余り値を寄せないため合計しても100%にはならない場合がある。
    //  * @see model.point.sensor.js => getPercentRed / getPercentYellow / getPercentGreen */
    // dispPercentGreen(ps) {
    //   return this.dispPercent(this.getPercent(ps.getPercentGreen()));
    // },
    // /** 黄の割合算出 */
    // dispPercentYellow(ps) {
    //   return this.dispPercent(this.getPercent(ps.getPercentYellow()));
    // },
    // /** 赤の割合算出 */
    // dispPercentRed(ps) {
    //   return this.dispPercent(this.getPercent(ps.getPercentRed()));
    // },
    click() {
      return new Promise((resolve) => {
        if (!this.timeout) {
          // click
          this.timeout = setTimeout(() => {
            this.timeout = null;
            resolve(this.tooltip);
          }, 200);
        }
      });
    },
    dbclick() {
      return new Promise((resolve) => {
        if (this.timeout) {
          // doubleClick
          console.log("doubleClick");
          this.$emit("stopAreaSearchInterval");
          clearTimeout(this.timeout);
          resolve(this.transition);
        }
      });
    },

    maxSensorWidth(){ 
      var maxSensor = this.area.getMaxSensorCount() >= 10 ? 10 : this.area.getMaxSensorCount();
      return 1.2 * maxSensor;
    },

    tooltip(ps) {
      const temp = !ps.visibleTooltip; 

      this.area.initVisibleTooltip();
      ps.visibleTooltip = temp;
    },
    transition(ps) {
      console.log("transition(ps)", ps, this.point);
      this.area.initVisibleTooltip();

      this.area.currentSensor = ps.sensor;
      this.area.currentHierarchy = this.user.findHierarchy(
        this.point.hierarchyCode
      );


      console.log(constant, store);

      this.$router
        .push({
          name: "sensor",
          params: {
            propSensorNumber: ps.sensor.sensorNumber,
            propHierarchy: this.area.currentHierarchy,
            propArea: this.area,
            propSearchDate: this.area.condition.searchDateEnd,
            propSearchPeriodValue: this.area.condition.getSearchPeriodValue(),
          },
          query: this.getQuery(),
        })
        .catch((error) => console.log(error));

    },
  },
};

export default vm;
</script>

<style scope>
.point-sensor-list {
  margin: auto;
  margin-bottom: 1vh;
  display: flex;
  justify-content: flex-start;
  /* align-items: flex-start; */
  align-content: baseline;
  flex-wrap: wrap;
  width: var(--point-sensor-width);
  min-height: inherit;
}

.point-sensor {
  cursor: pointer;
  margin: var(--point-sensor-margin);
  height: var(--point-sensor-size);
  width: var(--point-sensor-size);
  background-color: var(--point-sensor-color);
}

.tooltip-percent {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.tooltip-percent span {
  display: block;
  margin-left: 1vh;
}

.tooltip-color {
  height: 3vh;
  width: 3vh;
}

.sensor-green {
  background-color: var(--background-color-green);
}

.sensor-yellow {
  background-color: var(--background-color-yellow);
}

.sensor-red {
  background-color: var(--background-color-red);
}

.v-tooltip__content {
  font-size: x-large;
  opacity: 1 !important;
  background-color: #181818;
}
</style>
