<template>
  <div v-if="showFab">
    <div v-if="enabled" class="fab-operation">
      <v-dialog
        v-model="downloadDialog"
        class="download-dialog"
        persistent
        width="600px"
        
        scrollable
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="fab-button" v-if="downloadable">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="transparent"
              fab
              :small="isIphone"
              @click="downloadDialog = true"
            >
              <img
                v-if="processing"
                src="../assets/icon-progress-download.png"
              />
              <img v-else src="../assets/icon-download.png" />
            </v-btn>
          </div>
        </template>
        <v-card class="" dark style="height: 300px;">
          <div class="dialog-header">
            <v-layout wrap>
              <v-flex text-left  style="padding-top: 0.6em;">
                <span>CSVファイルダウンロード</span>
              </v-flex>
              <v-flex text-right mt-2>
                <v-btn
                  class="dialog-button-close"
                  color="transparent"
                  tile
                  @click="downloadDialog = false"
                >
                  <v-icon color="white" large>mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </div>
          <div class="csv-sub-header" :style="bindStyle">
            <v-layout wrap>
              <v-flex text-right ml-5 my-0>
                <date-picker-component
                  :propDisabled="processing"
                  :propUser="propUser"
                  :propDate="searchDate"
                  propLabel="検索日時"
                  @targetDate="targetDate"
                />
              </v-flex>
              <v-flex text-center>
                <search-period-component
                  :propDisabled="processing"
                  :propSearchPeriod="getSearchPeriod()"
                  @change="changeSearchPeriod"
                />
              </v-flex>
              <v-flex text-center mr-16 my-3 ml-3>
                <v-btn
                  :disabled="processing"
                  class=""
                  color=""
                  tile
                  @click="downloadCsv()"
                >
                  <v-icon color="white" large>mdi-note-plus-outline</v-icon>
                  CSVファイル準備
                </v-btn>
              </v-flex>
            </v-layout>
          </div>
          <div class="csv-sub-header" :style="bindStyle">
            <v-flex text-center>
              <v-btn
                :disabled="!isCreatedCsv || processing"
                class="m-3"
                color=""
                tile
                :href="csvInfoDownloadUrl"
                download
              >
                <span v-if="processing">
                  <v-icon color="white" large>mdi-progress-download</v-icon>
                  CSVファイル作成中
                </span>
                <span v-else>
                  <v-icon color="white" large>mdi-download</v-icon>
                  ダウンロード
                </span>
              </v-btn>
            </v-flex>
          </div>
          <div
            class="dialog-content"
            :key="keyDialogContent"
            style=""
          >
            <v-layout row mx-3>
              <v-flex>
                <v-flex>検索日</v-flex>
                <v-flex>作成開始時間</v-flex>
                <v-flex>有効期限</v-flex>
              </v-flex>
              <v-flex>
                <v-flex>{{ csvInfoSearchDate }}</v-flex>
                <v-flex>{{ csvInfoExecutedDatetime }}</v-flex>
                <v-flex>{{ csvInfoDownloadExpired }}</v-flex>
              </v-flex>
              <v-flex>
                <v-flex>期間</v-flex>
                <v-flex>ファイルサイズ</v-flex>
                <v-spacer></v-spacer>
              </v-flex>
              <v-flex>
                <v-flex>{{ csvInfoSearchPeriod }}</v-flex>
                <v-flex> {{ csvInfoFileSize }} </v-flex>
                <v-spacer></v-spacer>
              </v-flex>
            </v-layout>
          </div>
          <div class="dialog-footer" style="margin-bottom: 0.5em;">
            <v-layout row mx-3>
              <v-flex xs12>有効期限を過ぎると、CSVは削除されます。</v-flex>
              <v-flex xs12>再作成すると、CSVは上書きされます。</v-flex>
            </v-layout>
          </div>
        </v-card>
    </v-dialog>
    </div>
  </div>
</template>

<script>
import constant from "../store/constant";
import models from "../store/models";
import store from "../store";
import mixinApp from "../store/mixin.app";
import DatePickerComponent from "../components/DatePickerComponent";
import SearchPeriodComponent from "../components/SearchPeriodComponent";

const vm = {
  mixins: [mixinApp],
  components: {
    DatePickerComponent,
    SearchPeriodComponent,
  },
  props: {
    propDownloadable: false,
    propGetLoading: null,
    propConfigDialog: null
  },
  data() {
    return {
      downloadDialog: false,
      unprocessed: true,
      processing: false,
      processed: false,
      searchDate: null,
      searchPeriod: null,
      keyDialogContent: false,
      unsubscribe: () => { },
    };
  },
  computed: {
    enabled() {
      return this.user ? this.user.strategy.enabled() : false;
    },
    downloadable() {
      return this.propDownloadable;
    },
    showFab() {
      return !this.propGetLoading();
    },

    /**
      * CSV情報
      */
    csvInfo() {
      return store.getters[constant.storeDownloadAreaCsvGettersCsvInfo()];
    },
    isCreatedCsv() {
      return this.csvInfoDownloadUrl != null;
    },
    // 以後CSVダウンロードの情報のStore Getter
    /** CSV情報:DownloadUrl */
    csvInfoDownloadUrl() {
      if (this.csvInfo == null || this.csvInfo.downloadUrl == null) {
        return null;
      } else {
        return this.csvInfo.downloadUrl;
      }
    },
    /** CSV情報:SearchDate */
    csvInfoSearchDate() {
      if (this.csvInfo == null || this.csvInfo.condition.searchDate == null) {
        return "-";
      } else {
        const value = this.csvInfo.condition.searchDate;
        const valueDate = constant.toDate(value);
        const valueYmdString = valueDate.toLocaleString(
          "ja",
          constant.toLocaleStringYmdOptions()
        );
        return valueYmdString;
      }
    },
    /** CSV情報:SearchPeriod */
    csvInfoSearchPeriod() {
      if (this.csvInfo == null || this.csvInfo.condition.searchPeriod == null) {
        return "-";
      } else {
        return "直近 " + this.csvInfo.condition.searchPeriod + " 日";
      }
    },
    /** CSV情報:ExecutedDatetime */
    csvInfoExecutedDatetime() {
      if (
        this.csvInfo == null ||
        this.csvInfo.condition.executedDatetime == null
      ) {
        return "-";
      } else {
        const valueDate = constant.toDate(
          this.csvInfo.condition.executedDatetime
        );
        const valueYmdString = valueDate.toLocaleString(
          "ja",
          constant.toLocaleStringDateTimeOptions()
        );
        return valueYmdString;
      }
    },
    /** CSV情報:FileSize */
    csvInfoFileSize() {
      if (this.csvInfo == null || this.csvInfo.fileSize == null) {
        return "-";
      } else {
        const valueSize = this.csvInfo.fileSize / 1000;
        return valueSize.toFixed(1) + " KByte";
      }
    },
    /** CSV情報:DownloadExpired */
    csvInfoDownloadExpired() {
      if (this.csvInfo == null || this.csvInfo.downloadExpired == null) {
        return "-";
      } else {
        const valueDate = new Date(this.csvInfo.downloadExpired);
        //const valueDate = constant.toDate(value);
        const valueYmdString = valueDate.toLocaleString(
          "ja",
          constant.toLocaleStringDateTimeOptions()
        );
        return valueYmdString;
      }
    },
  },
  methods: {
    init() {
      this.unprocessed = true;
      this.processing = false;
      this.processed = false;
      this.searchDate = constant.toUnixTime(constant.today());
      this.searchPeriod = constant.createSearchPeriodDefault();
      this.keyDialogContent = !this.keyDialogContent;
    },

    async downloadCsv() {
      this.unprocessed = false;
      this.processing = true;
      const param = {
        condition: this.createSearchCondition(),
        sensorCount: 0, // ToDo: あとで取得処理を入れる
      };
      await store.dispatch(constant.storeDownloadCsvAreaAsync(), param);

      //
      this.unprocessed = true;

      this.processing = false;
    },
    getCsvName(filename) {
      return filename ? filename : new Date().toLocaleString() + ".zip";
    },
    targetDate(date) {
      this.searchDate = date;
    },
    changeSearchPeriod(searchPeriod) {
      this.searchPeriod = searchPeriod;
    },
    createSearchCondition() {
      const ret = models.createSearchCondition();

      ret.userKind = this.user.userKind;
      // 追加
      ret.userCode = this.user.userCode;
      ret.searchUnit = constant.searchUnitCsvArea();
      ret.hierarchyCode = this.user.getCurrentHierarchy().hierarchyCode;
      ret.searchDate = this.searchDate;
      ret.searchPeriod = this.searchPeriod.value;
      ret.executedDatetime = constant.toUnixTime(new Date());

      return ret;
    },
    getSearchPeriod() {
      return constant.searchPeriodDefault();
    },
    saveZip(csv, filename) {
      const blob = new Blob([csv], { type: "application/zip" });
      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(blob);
      link.download = this.getCsvName(filename);
      link.click();
    },
  },
  watch: {
    downloadDialog() {
      if (this.downloadDialog) {
        this.init();
      }
    },
  },
  created() {
    this.init();
    this.unsubscribe = store
      .subscribe((mutation, state) => {
        if (mutation.type === constant.storeDownloadCsvAreaAsync()) {
          this.saveZip(state.downloadArea.csv, state.downloadArea.filename);
          this.processing = false;
          this.processed = true;
        }
      })
      .bind(this);
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};

export default vm;
</script>

<style scoped>
.fab-operation {
  position: fixed;
  top: 16vh;
  right: 1vw;
}

.fab-button {
  cursor: pointer;
  height: 8vh;
  width: 8vh;
  margin-top: var(--fab-button-margin-top);
}

.fab-button-large {
  cursor: pointer;
  height: 20vh;
  width: 20vh;
  margin-top: 1vh;
}

.fab-button img {
  height: 6vh;
  width: 6vh;
}

.progress {
  width: 20vw;
}

.progress span {
  display: inline-block;
  margin: 1vh;
}

.checkbox {
  position: absolute;
  top: 4vh;
  font-size: 3vh;
}
</style>
