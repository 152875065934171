<template>
    <v-main class="main">
        <div class="point" :style="bindStyle" v-if="point.isNotEmpty()">
            <span class="point-header" :style="bindStyle" @dblclick="dbclick()">
                {{ point.hierarchyName }}
            </span>
            <point-sensor-component
                :propUser="user"
                :propArea="area"
                :propPoint="point"
                @stopAreaSearchInterval="stopAreaSearchInterval"
                :propSensorCount="maxSensorCountByRow" 
            />
        </div>
    </v-main>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinPoint from "../store/mixin.point";
import PointSensorComponent from "../components/PointSensorComponent";
import constant from "../store/constant";

const vm = {
    mixins: [mixinApp, mixinPoint],
    components: {
        PointSensorComponent,
    },
    props: {
        propGetPoint: null,
        propRow: null,
        propCol: null,
        propArea: null,
        propColMax: null,
    },
    computed: {
        bindStyle() {
            return Object.assign(this.bindStylePoint, {});
        },
        area() {
            return this.propArea;
        },
        point() {
            return this.propGetPoint(this.propRow, this.propCol, this.propColMax);
        },
        maxSensorCountByRow(){
            let maxCount = 0;
            for(let i=0; i<= this.propColMax;i++){
                const point = this.propGetPoint(this.propRow, i, this.propColMax);
                if (point == undefined || point == null){
                    continue;
                }
                const count = point.pointSensorList.length;
                maxCount = count > maxCount ? count : maxCount;
            }
            return maxCount;
        },       
        maxSensorHeightCount(){
            //Todo:1セル当たりの高さの取得方法の見直し
            return (Math.floor(this.maxSensorCountByRow / 10) + 1) * this.pointSensorSize() / 0.82;
            //return 8 * this.pointSensorSize() / 0.82;
        },       
        pointHeaderFontSize(){
            return 2/((this.propColMax/3)*1.1);
        },
    },
    methods: {
        dbclick() {
            this.user.setCurrentHierarchy(
                this.user.findHierarchy(this.point.hierarchyCode)
            );
            this.transitionRanking();
            this.stopAreaSearchInterval();
        },
        transitionRanking() {
            this.$router.push({
                name: "ranking",
                params: {
                    propUser: this.user,
                    propSummary: null,
                    propArea: this.area,
                    propSearchUnit: constant.searchUnitFloor(),
                    propRotationSelectNo: this.propRotation?.no,

                },
                query: this.getQuery(),
            });
        },
        /** 一定間隔で検索、を停止 */
        stopAreaSearchInterval() {
            this.$emit("stopAreaSearchInterval");
        },
        pointSensorHeight() {
      const h = this.getListHeight();

      return h - h * 0.3;
    },
    pointSensorWidth() {
      const w = this.getListWidth() / this.area.countMax(this.hierarchy);

      return w - w * 0.3;
    },
    pointSensorSize() {
      return this.pointSensorHeight() < this.pointSensorWidth()
        ? this.pointSensorHeight()
        : this.pointSensorWidth();
    },          
    },
};

export default vm;
</script>

<style scoped>
.point {
    border: 1px solid;
    border-radius: var(--point-border-radius);
    min-height: var(--point-sensor-height);
    width: var(--point-width);
    margin:auto;
    border-color: var(--grid-background-color);
}

.point-header {
    display: block;
    cursor: pointer;
    text-align: center;
    font-size: var(--point-Header-FontSize);
    white-space: nowrap;
}
</style>
