import constant from "./constant";


const RateHeader = 0.2;
const RateList = 1.0 - RateHeader;
const px = (val) => val + "px";
const mixinPoint = {
    computed: {
        bindStylePoint() {
            return {
                //"--col-height": px(this.getColHeight()),
                "--col-height": "auto",
                "--col-width": px(this.getColWidth()),
                "--point-height": px(this.getPointHeight()),
                "--point-width": px(this.getPointWidth()),
                //"--point-header-font-size": px(this.getHeaderFontSize()),
                "--point-border-radius": px(this.getPointBorderRadius()),
                "--list-height": px(this.getListHeight()),
                "--list-width": px(this.getListWidth()),
                "--background-color-green": constant.green(),
                "--background-color-yellow": constant.yellow(),
                "--background-color-red": constant.red(),  
                "--point-sensor-height":px(this.maxSensorHeightCount),
                // "--point-Header-FontSize":(this.pointHeaderFontSize) +"vw",
                "--point-Header-FontSize":px(this.getPointWidth()/18),
                "--scroll-height":(constant.pointPercentHeight() * 100) + "vh",                
            };
        },
        
    },
    methods: {
        getHeight(percent) {
            return (this.innerHeight * constant.pointPercentHeight() * (percent || 1.0));
        },
        getWidth(percent) {
            return (this.innerWidth * constant.pointPercentWidth() * (percent || 1.0));
        },
        getColHeight(percent) {
            return this.getHeight(percent) / this.gridColMax();
        },
        getColWidth(percent) {
            return this.getWidth(percent)  / this.gridColMax();
        },
        getPointMargin(percent) {
            return this.getColHeight(percent) / 10;
        },
        getPointHeight(percent) {
            return this.getColHeight(percent) - this.getPointMargin(percent);
        },
        getPointWidth(percent) {
            return this.getColWidth(percent);
        },
        // getHeaderFontSize() {
        //     if (this.point) {
        //         const len = constant.getLength(this.point.hierarchyName);
        //         const marginWidth = 0.85;
        //         const maxHeightSize = this.getPointHeight() / 6.5;

        //         const fontSize = (this.getPointHeight(RateHeader) * len) > (this.getPointWidth()) ? (this.getPointWidth() * marginWidth) / len : this.getPointHeight(RateHeader);
        //         return fontSize > maxHeightSize ? maxHeightSize : fontSize
        //     }
        //     return this.getPointHeight(RateHeader);
        // },
        getListHeight() {
            return this.getPointHeight(RateList);
        },
        getListWidth() {
            return this.getPointWidth(0.95);
        },
        getPointBorderRadius() {
            return this.getPointHeight() / 15;
        },
        
        gridColMax() {
            // 画面の横幅(innerWidth)渡す
            return this.area ? this.area.getGrid(innerWidth) : 1;
        },
        gridRow() {
            const gridMaxColCount = this.gridColMax();
            const gridCount = this.area ? this.area.getPointCount() : 1;
            const gridRowCount = gridMaxColCount + Math.ceil((Math.pow(gridCount,2) - Math.pow(gridMaxColCount,2)) / gridMaxColCount) ;
            return gridRowCount;
        },

    },
};

export default mixinPoint;