<template>
    <v-main class="main">
        <loading-component :propLoading="loading" />
        <header-component :propHeader="header" />
        <div>
            <!-- 動作としては本来はラジオボタンだが、チェックボックスで処理を作成していたためそのままチェックボックスを採用 -->
            <div class="sub-header-latest-search">
                <div>
                    <v-checkbox
                        :on-icon="'mdi-checkbox-marked-circle-outline'"
                        :off-icon="'mdi-checkbox-blank-circle-outline'"
                        class="search-type-checkbox"
                        v-model="searchLatestCheckbox"
                        color="white"
                        hide-details=ture
                        @click="searchLatestArea"
                    >
                    </v-checkbox>
                    <span class="search-type-label">リアルタイム表示</span>
                </div>
                <div class="usage-guide" :style="bindStyle">
                    <div class="sensor sensor-green" />
                    <div class="message">{{ hierarchy.lowerYellow }}ppm未満</div>
                    <div class="sensor sensor-yellow" />
                    <div class="message">{{ hierarchy.lowerYellow }}ppm以上</div>
                    <div class="sensor sensor-red" />
                    <div class="message">{{ hierarchy.lowerRed }}ppm以上</div>
                </div>
            </div>
            <div>
                <v-checkbox
                    :on-icon="'mdi-checkbox-marked-circle-outline'"
                    :off-icon="'mdi-checkbox-blank-circle-outline'"
                    class="search-type-checkbox"
                    v-model="searchPeriodCheckbox"
                    color="white"
                    hide-details=ture
                    @click="selectPeriodSearch"
                >
                </v-checkbox>
                <span class="search-type-label">期間指定表示</span>
                <span class="search-label" :style="bindSearchLatelStyle">▼検索条件</span>
            </div>
            <div class="search-condition">
                <date-picker-component
                    class="search-condition-item"
                    propLabel="開始日付"
                    :propUser="user"
                    :propDate="searchDateStart"
                    :propDisabled="disabledPeriodSearch"
                    @targetDate="setSearchDateStart"
                />
                <date-picker-component
                    class="search-condition-item"
                    propLabel="終了日付"
                    :propUser="user"
                    :propDate="searchDateEnd"
                    :propDisabled="disabledPeriodSearch"
                    @targetDate="setSearchDateEnd"
                />
                <v-dialog
                    v-model="dialog"
                    max-width="70%"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div class="time-label"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon 
                                class="time-label-icon"
                                color="white"
                                :style="bindSearchLatelStyle"
                            >
                                mdi-alert-circle
                            </v-icon>
                            <span :style="bindSearchLatelStyle">時間帯指定</span>
                        </div>
                    </template>
                    <v-card dark>
                    
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="dialog-close-button"
                                color="transparent"
                                tile
                                @click="dialog = false"
                            >
                                <v-icon color="white" large>mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                        <img class="dialog-img" src="../assets/area-time-caution.png" />
                    </v-card>
                </v-dialog>
                <time-picker-component
                    class="search-condition-item"
                    propLabel="開始時刻"
                    :propTime="searchTimeStart"
                    :propDisabled="disabledTimeSelect"
                    @targetTime="setSearchTimeStart"
                />
                <time-picker-component
                    class="search-condition-item"
                    propLabel="終了時刻"
                    :propTime="searchTimeEnd"
                    :propDisabled="disabledTimeSelect"
                    @targetTime="setSearchTimeEnd"
                />
                <v-checkbox
                    :on-icon="'mdi-checkbox-marked-outline'"
                    class="search-condition-item"
                    v-model="searchAlldayCheckbox"
                    color="white"
                    hide-details=ture
                    :disabled="disabledPeriodSearch"
                    @click="setAlldaySearchTime"
                >
                </v-checkbox>
                <span :style="bindSearchLatelStyle">終日</span>
                <!-- TODO:関連関数を削除するタイミングで削除する -->
                <!-- <span class="search-label">CO2濃度が</span>
                <v-select
                    class="search-condition-geco2c"
                    v-model="geCo2c"
                    :items="getGeCo2cList()"
                    return-object
                />
                <span class="search-label">の期間が</span>
                <v-select
                    class="search-condition-gepercent"
                    v-model="gePercent"
                    :items="getGePercentList()"
                    return-object
                /> -->
                <v-btn
                    :disabled="disabledSearch"
                    class="search-button"
                    color="transparent"
                    tile
                    large 
                    :style="bindSearchLatelStyle"
                    @click="searchPeriodArea"
                >
                    <img class="search-image" src="../assets/icon-search.png" />
                    <span :style="bindSearchLatelStyle">検索する</span>
                </v-btn>
                
            </div>
        </div>
        <v-container class="container" fluid :style="bindStyle" :key="containerKey">
            <v-row v-for="i in gridRow()" :key="i" align="center" justify="center" no-gutters>
            
                <v-col v-for="j in gridColMax()" class="grid-col" :key="j" :style="bindStyle">
                    <point-component
                        :propUser="user"
                        :propArea="area"
                        :propGetPoint="getPoint"
                        :propRow="i"
                        :propCol="j"
                        :propColMax="gridColMax()"
                        :style="bindStyle"
                        @stopAreaSearchInterval="stopAreaSearchInterval"
                    />
                </v-col>
            </v-row>
            <div class="footer">
                <div>{{ header.dispTimestamp() }} 更新</div>                                           
            </div>
        </v-container>
        <area-csv-component
            :propUser="user"
            :propArea="propArea"
            :propDownloadable="true"
            :propGetLoading="getLoading"
        />        
    </v-main>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinSensor from "../store/mixin.sensor";
import mixinPoint from "../store/mixin.point";
import LoadingComponent from "../components/LoadingComponent";
import HeaderComponent from "../components/HeaderComponent";
import DatePickerComponent from "../components/DatePickerComponent";
import TimePickerComponent from "../components/TimePickerComponent";
import PointComponent from "../components/PointComponent";
import AreaCsvComponent from "../components/AreaCsvComponent";
import models from "../store/models";
import store from "../store";
import constant from "../store/constant";

const vm = {
    mixins: [mixinApp, mixinSensor, mixinPoint],
    components: {
        LoadingComponent,
        HeaderComponent,
        DatePickerComponent,
        TimePickerComponent,        
        PointComponent,
        AreaCsvComponent,
    },
    data() {
        return {
            area: this.propArea,
            searchDateStart: constant.toUnixTime(constant.today()),
            searchDateEnd: constant.toUnixTime(constant.today()),
            searchTimeStart: constant.totime(constant.getNowUnixTime()),
            searchTimeEnd: constant.totime(constant.getNowUnixTime()),
            geCo2c: null,
            gePercent: null,
            containerKey: false,
            processing: false,
            searchLatestCheckbox: false,
            searchPeriodCheckbox: false,
            searchAlldayCheckbox: false,
            dialog: false,
        };
    },
    /*
    注意：
    エリア画面戻るボタン障害対応
    戻った際、本来ならthis.hierarchyとthis.user.hierarchyが同じものをさすことが前提になっているが、これがthis.hierarchyの中身が前の画面のものになっている。
    this.hierarchyを使っている箇所をthis.user.hierarchyにすることで対応
    */
    computed: {
        loading() {
            return this.processing;
        },
        header() {
            return models.createHeader(this.user?.hierarchy?.hierarchyName, "icon-area", this.summary?.getLatestTimestamp);
        },       
        bindStyle() {
            return Object.assign(
                mixinApp.computed.bindStyle.call(this),
                Object.assign(this.bindStylePoint, {
                    "--font-size-message": this.innerWidth > 1024 ? "medium" : "x-small",
                })
            );            
        },
        disabledSearch() {
            return this.processing || this.searchLatestCheckbox || !this.searchPeriodCheckbox;
        },
        disabledTimeSelect() {
            return this.searchLatestCheckbox || this.searchAlldayCheckbox || !this.searchPeriodCheckbox;
        },
        disabledPeriodSearch() {
            return this.searchLatestCheckbox || !this.searchPeriodCheckbox;
        },
        bindSearchLatelStyle() {
            if(this.searchLatestCheckbox || !this.searchPeriodCheckbox){
                return {
                    "--search-condition-label-opacity": 0.5,
                    "--search-button-background-color": this.getMainBackgroundColor(),
                };
            }else{
                return {
                    "--search-condition-label-opacity": 1,
                };
            }
        },
    },
    methods: {
        setAlldaySearchTime() {
            if(this.searchAlldayCheckbox){
                this.searchTimeStart = "00:00";
                this.searchTimeEnd = "00:00";
            }
        },
        searchPeriodArea() {
            this.processing = true;
            store.dispatch(constant.storeAreaSearch(), this.createAreaSearchCondition());
        },
        /** エリアの最新検索 */
        searchLatestArea() {
            if(this.searchLatestCheckbox){
                this.searchPeriodCheckbox = false;
                this.searchAlldayCheckbox = false;
                this.processing = true;
                //検索
                store.dispatch(
                    constant.storeAreaSearch(),
                    this.createLatestAreaSearchCondition()
                );
                //一定間隔で検索
                this.startAreaSearchInterval(this.createLatestAreaSearchCondition);
            }else{
                this.searchPeriodCheckbox = true;
                //一定間隔で検索、を停止
                this.stopAreaSearchInterval();

            }
        },
        /** 一定間隔で検索 */
        startAreaSearchInterval(createSearchCondition) {
            if (this.intervalAreaSearch) {
                return;
            }

            this.intervalAreaSearch = setInterval(
                (
                    () => {
                        store.dispatch(constant.storeAreaSearch(), createSearchCondition());
                    }).bind(this),
                constant.intervalMs()
            );
        },
        selectPeriodSearch() {
            if(this.searchPeriodCheckbox){
                this.searchLatestCheckbox = false;
                //一定間隔で検索、を停止
                this.stopAreaSearchInterval();
            }else{
                this.searchLatestCheckbox = true;
                // エリアの最新検索
                this.searchLatestArea();
            }

        },
        /** 一定間隔で検索、を停止 */
        stopAreaSearchInterval() {
            clearInterval(this.intervalAreaSearch);
            this.intervalAreaSearch = null;
        },        
        createAreaSearchCondition() {
            const ret = models.createAreaSearchCondition();

            ret.userCode = this.user.userCode;
            ret.userKind = this.user.userKind;
            // Area画面への戻るボタン遷移時に対応
            ret.hierarchyCode = this.user.hierarchy.hierarchyCode;
            ret.searchDateStart = this.searchDateStart;
            ret.searchDateEnd = this.searchDateEnd;
            ret.searchTimeStart = this.searchTimeStart;
            ret.searchTimeEnd = this.searchTimeEnd;
            ret.geCo2c = this.geCo2c.value;
            ret.gePercent = this.gePercent.value;

            return ret;
        },
        /** エリアの最新検索の検索条件 */
        createLatestAreaSearchCondition() {
            const ret = models.createAreaSearchCondition();

            ret.userCode = this.user.userCode;
            ret.userKind = this.user.userKind;
            // Area画面への戻るボタン遷移時に対応
            ret.hierarchyCode = this.user.hierarchy.hierarchyCode;
            ret.hierarchyName = this.user.hierarchy.hierarchyName;



            const latestTime = 10 * 90;
            const nowUnixTime = constant.getNowUnixTime();
            ret.searchDateStart = constant.getNormalizeUnixtime(nowUnixTime - latestTime);
            ret.searchDateEnd = constant.getNormalizeUnixtime(nowUnixTime);
            ret.searchTimeStart = constant.totime(nowUnixTime - latestTime);
            ret.searchTimeEnd = constant.totime(nowUnixTime);
            // CO2濃度と期間の条件は常に「全て(0)」とする
            ret.geCo2c = 0;
            ret.gePercent = 0;

            this.searchDateStart = ret.searchDateStart;
            this.searchDateEnd = ret.searchDateEnd;
            this.searchTimeStart = ret.searchTimeStart;
            this.searchTimeEnd = ret.searchTimeEnd;
            
            return ret;
        },
        getPoint(i, j, colMax) {
            return this.area ? this.area.getPoint(i, j, colMax) : models.createPoint();
        },
        setSearchDateStart(date) {
            this.searchDateStart = date;
        },
        setSearchDateEnd(date) {
            this.searchDateEnd = date;
        },
        setSearchTimeStart(time) {
            this.searchTimeStart = time;
        },
        setSearchTimeEnd(time) {
            this.searchTimeEnd = time;
        },
        createGeCo2c(value) {
            return {
                text: value > 0 ? value + "ppm以上" : "全て",
                value: value,
            };
        },
        createGeCo2cDefault() {
            return this.createGeCo2c(this.user.hierarchy.lowerYellow);
        },
        getGeCo2cList() {
            return [
                this.createGeCo2c(0),
                this.createGeCo2cDefault(),
                this.createGeCo2c(this.user.hierarchy.lowerRed),
            ];
        },
        createGePercent(value) {
            return { text: Math.floor(value * 100) + "%以上", value: value };
        },
        createGePercentDefault() {
            return this.createGePercent(0.5);
        },
        getGePercentList() {
            return [
                this.createGePercent(0.3),
                this.createGePercentDefault(),
                this.createGePercent(0.7),
                this.createGePercent(0.9),
            ];
        },
        afterResize() {
            this.containerKey = !this.containerlKey;
        },
    },
    created() {
        if (!this.user || this.user.unauthorized()) {
            this.transitionRoot();
            return;
        }

        this.unsubscribe = store
            .subscribe((mutation, state) => {
                if (mutation.type === constant.storeAreaSearch()) {
                    this.area = state.area.area;
                    // this.area.sort();
                    this.processing = false;
                } 
            })
            .bind(this);

        if (this.area) {
            this.searchDateStart = this.area.condition.searchDateStart;
            this.searchDateEnd = this.area.condition.searchDateEnd;
            this.searchTimeStart = this.area.condition.searchTimeStart;
            this.searchTimeEnd = this.area.condition.searchTimeEnd;
            this.geCo2c = this.createGeCo2c(this.area.condition.geCo2c);
            this.gePercent = this.createGePercent(this.area.condition.gePercent);
            // 画面遷移の場合は期間指定表示をdefaultとする
            this.searchPeriodCheckbox = true;
        } else {
            // CO2濃度と期間の条件は常に「全て(0)」とする
            this.geCo2c = this.createGeCo2c(0);
            this.gePercent = this.createGePercent(0);
            // リアルタイム表示をdefaultにする;
            this.searchLatestCheckbox = true;
            this.searchLatestArea();
        }

    },
};

export default vm;
</script>

<style scoped>
.sub-header-latest-search {
    display: flex;
    align-items: center;
}

.search-type-checkbox {
    display: inline-block;
    margin:  0 0 0 1vw;
}

.search-type-label {
    font-size: x-large;
}

.search-condition {
    margin-left: 2vw;
}

.search-condition-item {
    display: inline-block;
    margin-left: 0.5vw;
}

.time-label-icon {
    display: block;
}

.time-label {
    display: inline-block;
    margin-left: 1vw;
}

.search-condition-geco2c {
    display: inline-block;
    max-width: 144px;
    margin-left: 0.5vw;
}

.search-condition-gepercent {
    display: inline-block;
    max-width: 112px;
    margin-left: 0.5vw;
}


.search-label {
    margin-left: 1vw;
    opacity: var(--search-condition-label-opacity);
}

.search-condition .v-icon,
.search-condition .v-btn,
.search-condition span {
    opacity: var(--search-condition-label-opacity);
}

.search-button {
    margin-left: 1vw;
    margin-right: 0.5vw;
}

.search-image {
    height: 6vh;
    width: 6vh;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg{
    background-color: var(--search-button-background-color) !important;
}

.dialog-img {
    margin: 2vw;
    max-width: 90%;
}

.dialog-close-button {
    cursor: pointer;
    height: 20vh;
    width: 20vh;
    margin-top: 1vh;
}

.usage-guide {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: auto;
    margin-right: 2vw;
}

.usage-guide .sensor {
    height: 1.5vw;
    width: 1.5vw;
    margin: 0.5vw;
    margin-left: 1vw;
}

.usage-guide .sensor-green {
    background-color: var(--background-color-green);
}

.usage-guide .sensor-yellow {
    background-color: var(--background-color-yellow);
}

.usage-guide .sensor-red {
    background-color: var(--background-color-red);
}

.usage-guide .message {
    font-size: var(--font-size-message);
}

.grid-col {
    height: var(--col-height);
    width: var(--col-width);
    margin-bottom: 30px;
}

</style>
